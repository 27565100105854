export default function observer({
  target,
  threshold = Array.from({ length: 100 }, (_, i) => i / 100),
  isVisible = () => {},
  hasExited = () => {},
  entriesCallback = (entry) => {
    const ratio = entry.intersectionRatio;
    const { top } = entry.intersectionRect;
    const shouldPlay = ratio > 0 && top >= 0;
    const shouldStop = ratio === 0 && top === 0;
    if (shouldPlay) {
      isVisible(ratio);
    } else if (shouldStop) {
      hasExited();
    }
  },
}) {
  let options = {
    rootMargin: "0px",
    threshold,
  };
  let callback = (entries) => {
    entries.forEach(entriesCallback);
  };
  let observer = new IntersectionObserver(callback, options);
  observer.observe(target);
}

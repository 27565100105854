<template>
  <ParallaxContainer tag="main" class="about">
    <ParallaxElement :compensate="false" class="bg" />
    <section class="hero">
      <ParallaxContainer tag="header" class="hero-wrap">
        <ParallaxElement
          tag="p"
          :factor="1"
          :compensate="false"
          class="hero-intro font-size-large"
          >{{ about.shortBio }}</ParallaxElement
        >
      </ParallaxContainer>
    </section>
    <section class="reel">
      <h3 class="vertical-text font-size-regular">Design / Development Reel</h3>
      <DeviceContainer
        :deviceType="false"
        :autoplay="true"
        :src="reelUrl"
        alt="Designer/Dev Reel"
        placeholder="/assets/about/reel-low-placeholder.webp"
      />
    </section>
    <section class="experience">
      <header>
        <h4 class="font-weight-regular" v-html="about.experience.header" />
      </header>
      <ul>
        <li v-for="c in about.experience.companies" :key="c">
          <figure role="img" :aria-label="`${toTitleCase(c)} Logo`">
            <img :src="`assets/about/logo-${c}.png`" :alt="toTitleCase(c)" />
          </figure>
        </li>
      </ul>
    </section>
    <section class="specialize">
      <header>
        <h4 class="font-weight-regular" v-html="about.specialize.header" />
      </header>
      <ul class="specialties">
        <Accordion
          tag="li"
          :content="about.specialize.development"
          title="Development"
        />
        <Accordion tag="li" :content="about.specialize.design" title="Design" />
        <Accordion
          tag="li"
          :content="about.specialize.creative"
          title="Creative Direction"
        />
      </ul>
    </section>
    <WakaTime />
    <DribbbleShots />
    <section class="resume">
      <p class="font-size-large">{{ about.resume }}</p>
      <AppButton icon="file">
        <a
          :href="`/resume.pdf?cachebust=${randomId()}`"
          slot="outer"
          target="_blank"
          aria-label="View Resume"
        />
        View Resume
      </AppButton>
      <AppButton class="secondary" icon="arrow-right">
        <router-link slot="outer" to="/" aria-label="View Latest Work" />
        View Work
      </AppButton>
    </section>
    <InstagramFeed />
    <section class="archives">
      <router-link to="work/archives" class="link">
        <h4 class="font-weight-regular font-weight-bold">See More Work In</h4>
        <span
          class="font-size-huge font-weight-black font-outline"
          data-title="Archives"
          >Archives</span
        >
      </router-link>
    </section>
  </ParallaxContainer>
</template>

<script>
// State
import { mapState, mapGetters } from "vuex";

// Components
import Accordion from "@/components/Accordion";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import DeviceContainer from "@/components/DeviceContainer";
import ParallaxContainer from "@/components/ParallaxContainer";
import ParallaxElement from "@/components/ParallaxElement";
import WakaTime from "@/components/WakaTime.vue";
import InstagramFeed from "@/components/InstagramFeed.vue";
import DribbbleShots from "@/components/DribbbleShots";

// Transitions
import onLeave from "@/transitions/onLeave";

// Utils
import toTitleCase from "@/utils/toTitleCase";
import randomId from "@/utils/randomId";

export default {
  name: "about",
  computed: {
    ...mapGetters({
      about: "about",
    }),
  },
  beforeRouteLeave(to, from, next) {
    onLeave.call(this, { to, from, next });
  },
  components: {
    AppIcon,
    Accordion,
    AppButton,
    DeviceContainer,
    ParallaxContainer,
    ParallaxElement,
    WakaTime,
    InstagramFeed,
    DribbbleShots,
  },
  data() {
    return {
      reelUrl: "",
    };
  },
  methods: {
    randomId,
    toTitleCase,
  },
  mounted() {
    this.reelUrl =
      "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/reel%2Freel-low.mp4?alt=media&token=0b7c5f0a-5058-41b5-9ce6-c24c9cc8dc79";
  },
};
</script>

<style lang="scss">
@import "../styles/mixins";
@import "../styles/vars";

// - All Sections
// -------------------------------------------

.about section {
  padding-right: vw(var(--gutter));
  padding-left: vw(var(--gutter));
  color: hsl(var(--color-font-dark));
  width: 100%;
  header {
    padding: vw(calc(var(--gutter) * 1)) 0;
  }

  .vertical-text {
    position: absolute;
    left: 0;
    top: 0;
    writing-mode: vertical-lr;
    width: vw(var(--gutter));
    display: flex;
    align-items: center;
    font-family: $font-weight-bold;
  }
}

// - Background Element
// -------------------------------------------

.about .bg {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 150vh;
  z-index: 0;
  opacity: 0;
  @include bp(mobile) {
    height: 130vh;
  }
}

.about .hero {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  @include bp(mobile) {
    height: 80vh;
  }
  p.hero-intro {
    width: 75%;
    margin: 0;
    position: relative;
    @include bp(mobile) {
      width: auto;
    }
  }
  button {
    margin-top: vh(60);
  }
  .hero-wrap {
    width: 100%;
    position: relative;
    z-index: 1;
  }
}

// - Reel
// -------------------------------------------

.about .reel {
  width: 100%;
  padding: 0 vw(var(--gutter));
  position: relative;
  video {
    width: 100%;
    display: block;
    cursor: pointer;
  }

  @include bp(mobile) {
    padding: 0;
    .vertical-text {
      display: none;
    }
  }
}

// - Experience
// -------------------------------------------

.experience {
  ul {
    margin: 0;
    padding: 0;
    display: grid;
    list-style: none;
    grid-gap: vw(90);
    grid-template-columns: repeat(5, 1fr);
    @include bp(mobile) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: vw(30);
    }
  }
  ul figure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  ul figure img {
    object-fit: contain;
    width: vw(100);
    max-height: vw(75);
    display: block;
    filter: hue-rotate(275deg) brightness(0.5);
    @include bp(mobile) {
      width: vw(60);
      max-height: vw(48);
    }
  }
}

// - Specialties
// -------------------------------------------

.specialties {
  margin: 0;
  padding: 0;
  list-style: none;
}

// - Resume
// -------------------------------------------

.resume {
  padding: vw(var(--gutter)) vw(var(--gutter)) 0;
  p {
    margin: 0 0 vw(calc(var(--gutter) / 2));
    width: 75%;
    @include bp(mobile) {
      width: auto;
    }
  }

  .app-button {
    margin: 0 0 vw(var(--gutter));
  }
  .app-button:not(:first-of-type) {
    margin-left: vw(30);
    float: right;
    @include bp(mobile) {
      float: none;
      margin-left: 0;
      margin-top: vw(30);
      display: block;
    }
  }
}

.archives {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 vw(var(--gutter)) 0;
  text-align: center;
  .link {
    display: inline-flex;
    flex-direction: column;
    margin-top: vw(30);
    @include bp(tablet) {
      margin: vw(80) 0;
    }
  }
}
</style>

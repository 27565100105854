<template>
  <ParallaxContainer tag="section" class="wakatime">
    <header>
      <h4 class="font-weight-regular">
        What I've been coding with in the last 7 days
      </h4>
    </header>
    <div class="data">
      <h3 class="vertical-text font-size-regular">Via WakaTime</h3>
      <div v-for="(lang, idx) in languages" :key="`lang-${idx}`" class="lang">
        <div
          class="bar"
          :data-percent="lang.percent"
          :style="{ width: `${lang.percent}%` }"
        />
        <p class="name" v-html="lang.name" />
        <span class="percent">{{ lang.percent.toFixed(2) }}%</span>
      </div>
    </div>
  </ParallaxContainer>
</template>

<script>
// Components
import ParallaxContainer from "@/components/ParallaxContainer";

// State
import { mapGetters } from "vuex";

// Utils
import { TweenMax } from "gsap";

export default {
  name: "wakatime",
  components: {
    ParallaxContainer,
  },
  data() {
    return {
      hasFetched: false,
    };
  },
  computed: {
    ...mapGetters({
      wakatime: "wakatime",
    }),
    languages() {
      const data = this.wakatime.find((obj) => {
        const [val] = Object.keys(obj);
        if (val && val === "languages") return obj;
      });
      if (data && data.languages) {
        const { languages } = data;
        return languages
          .sort((a, b) => b.percent - a.percent)
          .reduce((all, lang, idx) => {
            delete lang.color;
            if (idx <= 4) {
              all.push(lang);
              if (idx === 4) {
                lang.name = "Other";
              }
            } else {
              all[4].percent += lang.percent;
            }
            return all;
          }, []);
      } else {
        return Array.from({ length: 4 }, (v, i) => {
          return {
            name: "—",
            percent: 0.0,
          };
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("getWakaTime");
  },
};
</script>

<style lang="scss">
@import "../styles/mixins";
@import "../styles/vars";

.wakatime {
  position: relative;
  padding-bottom: vw(calc(var(--gutter) * 1));
}

.about section.wakatime header {
  padding-top: vw(calc(var(--gutter) * 0.5));
  padding-bottom: vw(calc(var(--gutter) * 0.5));
}

.about section.wakatime .vertical-text {
  transform: translateX(-100%);
  @include bp(mobile) {
    writing-mode: lr;
    position: relative;
    margin-bottom: vw(30);
    transform: none;
    width: auto;
  }
}

.wakatime .data {
  position: relative;
  display: flex;
  flex-direction: column;
}

.wakatime .lang {
  position: relative;
  margin-bottom: vw(5);
  padding: vw(10) vw(15);
}

.wakatime .bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--color-gradient);
  opacity: 0.5;
  height: 100%;
  width: 0%;
  transition: width 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.wakatime .name {
  margin: 0;
  font-family: $font-weight-bold;
  display: flex;
  align-items: center;
  position: relative;
}

.wakatime .percent {
  color: var(--theme-dark);
  position: relative;
}
</style>

<template>
  <AccordionWrapper :tag="tag" class="accordion">
    <div class="title">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <line
          fill="none"
          stroke="var(--theme-dark)"
          stroke-miterlimit="10"
          x1="12"
          y1="2"
          x2="12"
          y2="22"
          v-if="!ifMenuActive"
        ></line>
        <line
          fill="none"
          stroke="var(--theme-dark)"
          stroke-miterlimit="10"
          x1="22"
          y1="12"
          x2="2"
          y2="12"
        ></line>
      </svg>
      <h2
        class="font-weight-black font-outline-inverse"
        @click="ifMenuActive = !ifMenuActive"
        :data-title="title"
        v-html="title"
      />
    </div>
    <transition @enter="enter" @leave="leave" :css="false">
      <ul class="content" v-show="ifMenuActive">
        <li
          class="font-weight-regular"
          v-for="item in content"
          :key="item"
          v-html="item"
        />
      </ul>
    </transition>
  </AccordionWrapper>
</template>

<script>
import { TimelineMax } from "gsap";

const AccordionWrapper = {
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  render(h) {
    return h(this.tag, this.$slots.default);
  },
};

export default {
  name: "accordion",
  components: {
    AccordionWrapper,
  },
  props: {
    tag: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: Array,
    },
  },
  data() {
    return {
      ifMenuActive: true,
      categoryHeight: 0,
    };
  },
  methods: {
    enter(el, done) {
      const links = el.children;

      const height = Array.from(links).reduce((all, link) => {
        const padding = parseFloat(
          getComputedStyle(link).paddingTop.replace(/px/, "")
        );
        all += link.clientHeight / 2;
        return all;
      }, 0);

      new TimelineMax({ onComplete: done })
        .fromTo(
          el,
          0.3,
          {
            height: "0px",
          },
          {
            height: height + "px",
            ease: Expo.easeInOut,
          },
          0
        )
        .staggerFromTo(
          links,
          0.15,
          {
            autoAlpha: 0,
            y: -5,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: Circ.easeOut,
          },
          0.015,
          0.15
        );
    },
    leave(el, done) {
      const links = Array.from(el.children);
      new TimelineMax({ onComplete: done })
        .to(
          el,
          0.3,
          {
            height: "0px",
            ease: Expo.easeInOut,
          },
          0
        )
        .to(
          links,
          0.15,
          {
            autoAlpha: 0,
            y: -50,
            ease: Expo.easeIn,
          },
          0
        );
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.ifMenuActive = false;
    }
  },
};
</script>

<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: vw(30) 0;
  border-top: 1px dotted hsl(var(--color-font), 80%);
  border-bottom: 1px dotted hsl(var(--color-font), 80%);
  margin-bottom: -1px;
  .title {
    display: flex;
    align-items: center;
    user-select: none;
    text-transform: uppercase;
    svg {
      margin-right: vw(15);
    }
    h2 {
      cursor: pointer;
    }
  }
  .content {
    padding: 0 0 0 vw(35);
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    > li {
      padding: vw(15) 0;
    }
  }
  @include bp(mobile) {
    .content {
      padding: 0;
      grid-gap: 0 vw(15);
    }
  }
}
</style>

<template>    
    <ParallaxContainer tag='section' class='instagram-feed' v-if='!isInstagramPlaceholder'>
        <header>
            <h3 class='vertical-text font-size-regular'>Instagram</h3>
        </header>
        <figure v-for='(pic, n) in instagram' :key='pic.id + n' class='instagram'>
            <a tabindex='-1' :href='pic.permalink' target='_blank' class='instagram-link'><AppIcon name='instagram'/></a>
            <img 
                v-if='pic.media_type !== "VIDEO"'
                :src='pic.media_url' 
                :alt='pic.caption'
            />
            <video 
                v-else
                loop
                preload
                autoplay
                playsinline
                :poster='pic.thumbnail_url'
                :src='pic.media_url'
            />
        </figure>
    </ParallaxContainer>
</template>

<script>
import AppIcon from '@/components/AppIcon.vue';
import ParallaxContainer from '@/components/ParallaxContainer';
import ParallaxElement from '@/components/ParallaxElement';

// Store
import { mapGetters } from 'vuex';

export default {
    name: 'instagram-feed',
    components: {
        AppIcon,
        ParallaxContainer,
        ParallaxElement
    },
    computed: {
        ...mapGetters({
            instagram: 'instagram',
            isInstagramPlaceholder: 'isInstagramPlaceholder'
        })
    },
    created() {
        this.$store.dispatch('getInstagram');
    }
}
</script>

<style lang='scss'>

@import '../styles/vars';
@import '../styles/mixins';

.about section.instagram-feed {
    position: relative;
    padding-right: 0;
    display: grid;
    grid-gap: vw(45);
    grid-template-columns: repeat(5, 1fr);
    user-select: none;
    margin: 0 0 vw(var(--gutter)) 0;
    @include bp(tablet) {
        grid-gap: vw(15);
    }
    @include bp(mobile) {
        grid-gap: vw(5);
        grid-template-columns: repeat(3, 1fr);
    }
}

.instagram-feed header {
    position: absolute;
    top: 0;
    left: 0;
    @include bp(mobile) {
        position: relative;
        grid-column: span 3;
    }
}

.about section.instagram-feed header .vertical-text {
    @include bp(mobile) {
        writing-mode: lr;
        position: relative;
        margin-bottom: vw(10);
    }
}

.instagram {
    overflow: hidden;
    position: relative;
    display: block;
}

.instagram:nth-of-type(1) {
    grid-column: span 2;
    grid-row: span 2;
}

.instagram:last-of-type {
    @include bp(mobile) {
        display: none;
    }
}

.instagram img,
.instagram video {
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.instagram-link {
    --size: 24;
    position: absolute; 
    top: 1.5vw;
    right: 1.5vw;
    z-index: 2;
    width: vw(var(--size));
    height: vw(var(--size));
    .app-icon {
        opacity: 0.6;
        fill: white;
        width: 100%;
        height: auto;
    }
    .app-icon:hover {
        opacity: 1;
    }
}

</style>
<template>
    <section class='dribbble-shots'>
        <h3 class='vertical-text font-size-regular'>Dribbble Shots</h3>
        <ul ref='dribbbleList'>
            <li
                v-for='shot in dribbbleShots'
                :key='shot.id'
            >
                <figure 
                    class='dribbble-figure'
                    :class='{ "not-loaded": notLoaded }'
                >
                    <a 
                        :href='shot.html_url'
                        rel='noopener'
                        target='_blank'
                    > 
                        <img
                            v-if='shot.images'
                            :width='400'
                            :height='300'
                            :src='shot.images.two_x'
                        />
                    </a>
                </figure>
            </li>
        </ul>
    </section>
</template>

<script>
// Components 
import ParallaxContainer from '@/components/ParallaxContainer';
import ParallaxElement from '@/components/ParallaxElement';

// State
import { mapGetters } from 'vuex';

// Utils
import observer from '@/utils/observer';
import { TweenMax } from 'gsap';

export default {
    name: 'dribbble-shots',
    components: {
        ParallaxContainer,
        ParallaxElement
    },
    data() {
        return {
            hasFetched: false
        }
    },
    computed: {
        ...mapGetters({
            dribbbleShots: 'dribbbleShots',
            isDribbblePlaceholder: 'isDribbblePlaceholder'
        }),
        notLoaded() {
            return (this.isDribbblePlaceholder) || !this.hasFetched;
        }
    },
    watch: {
        dribbbleShots(val) {
            if (!this.isDribbblePlaceholder) {
                this.$nextTick(this.onDribbbleImages);
            }
        }
    },
    methods: {
        onDribbbleImages() {
            const images = this.$refs.dribbbleList.querySelectorAll('img');
            TweenMax.staggerFromTo(images, 1, {
                opacity: 0
            }, {
                opacity: 1,
                ease: Sine.easeOut,
                onStart() {
                    images.forEach(img => img.style.transition = 'none');
                },
                onComplete() {
                    images.forEach(img => img.style.transition = null);
                }
            }, 0.05);
        }
    },
    mounted() {
        observer ({
            target: this.$el,
            threshold: [0, 1],
            isVisible: async () => {
                if (this.hasFetched) return;
                this.$store.dispatch('getDribbble');
                this.hasFetched = true;
            }
        });
    }
}
</script>

<style lang='scss' scoped>

@import '../styles/mixins';
@import '../styles/vars';

.dribbble-shots {
    background: var(--theme-dark);
    padding-top: vw(var(--gutter));
    padding-bottom: vw(var(--gutter));
    color: var(--theme-highlight);
    position: relative;
}

.dribbble-shots .vertical-text {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    padding: 0;
    top: initial;
}

.dribbble-shots ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: vw(45);
    list-style: none;
    margin: 0;
    padding: 0;
    li:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;

        .dribbble-figure img {
            object-fit: cover;
        }
    }
}

.dribbble-figure {
    width: 100%;
    height: 100%;
    margin: 0;
    display: block;
    padding-bottom: 72.5%;
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
        transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    &:hover img {
        transform: scale(1.05);
    }
    &.not-loaded {
        background: rgba(black, 0.15);
        img {
            opacity: 0;
        }
    }
}
.dribbble-figure a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

@include bp(tablet) {
    .dribbble-shots {
        padding-bottom: vw(calc(var(--gutter) + 200));
    }
    .dribbble-shots ul {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include bp(mobile) {
    .dribbble-shots .vertical-text {
        position: relative;
        writing-mode: lr;
        position: relative;
        margin-bottom: vw(30);
        transform: none;
        width: auto;
    }
    .dribbble-shots {
        padding-bottom: vw(var(--gutter));
    }
    .dribbble-shots ul {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: vw(var(--gutter));
    }
}
</style>
<template>
    <div class='device-container size-contain' :class='deviceClass'>
        <svg class='icon-play' @click='onVideoClick' viewBox='0 0 64 64'>
            <path v-if='!isPlaying' d='M32,2A30,30,0,1,0,62,32,30.034,30.034,0,0,0,32,2ZM47.5,32.864l-24,14A1,1,0,0,1,22,46V18a1,1,0,0,1,1.5-.864l24,14a1,1,0,0,1,0,1.728Z'/>
            <path v-else d='M32,2A30,30,0,1,0,62,32,30.034,30.034,0,0,0,32,2ZM28,44a1,1,0,0,1-1,1H20a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1Zm17,0a1,1,0,0,1-1,1H37a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1Z'/>
        </svg>
        <video ref='video' muted :poster='posterImage' loop :src='`${src}`'/>
        <img v-if='deviceType' :src='deviceImage'/>
        <img v-if='deviceType' class='shadow' :src='deviceImage'/>
    </div>
</template>

<script>
import observer from '@/utils/observer';
import toJPG from '@/utils/toJPG';

export default {
    name: 'device-container',
    props: {
        deviceType: {
            type: [String, Boolean],
            default: false
        },
        placeholder: {
            type: String
        },
        src: {
            type: String
        },
        alt: {
            type: String
        },
        autoplay: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isPlaying: false
        }
    },
    computed: {
        posterImage() {
            const ua = navigator.userAgent.toLowerCase();
            const webpOkay = /chrome|firefox/g.test(ua);
            return webpOkay ? this.placeholder : toJPG(this.placeholder);
        },
        deviceClass() {
            let { deviceType } = this;
            deviceType = !deviceType ? 'no-container' : deviceType.toLowerCase();
            return { 
                [deviceType]: deviceType, 
                'is-playing': this.isPlaying
            };
        },
        deviceImage() {
            switch(this.deviceType.toLowerCase()) {
                case 'android':
                    return '/assets/google-pixel-4.png';
                case 'iphone':
                    return '/assets/iphone-x.png';
                case 'ipad':
                    return '/assets/ipad-pro.png';
                default:
                    return false;
            }
        }
    },
    methods: {
        observeVideo(video) {
            const {playVideo, pauseVideo} = this;
            observer({
                target: video,
                isVisible(ratio) {
                    playVideo(video);
                },
                hasExited() {
                    pauseVideo(video);
                }
            })
        },
        onVideoClick(event) {
            const icon = event.target;
            const video = icon.nextElementSibling;
            if (this.isPlaying) this.pauseVideo(video);
            else this.playVideo(video);
        },
        playVideo(video) {
            if (video.paused) {
                video.play();
            }
            this.isPlaying = true;
        },
        pauseVideo(video) {
            if (!video.paused) video.pause();
            this.isPlaying = false;
        }
    },
    mounted() {
        if (this.autoplay) {
            const { video } = this.$refs;
            this.observeVideo(video);
            setTimeout(() => {
                if (!video.paused) {
                    this.isPlaying = true;
                }
            }, 250);
        }
    }
}
</script>

<style lang='scss'>

@import '../styles/mixins';

.device-container {
    position: relative;
    align-self: flex-start;
    justify-self: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 1;
    video {
        width: 100%;
        height: auto;
        display: block;
    }
    img {
        pointer-events: none;
    }
    img.shadow {
        position: absolute;
        filter: blur(15px);
        opacity: 0.5;
        z-index: -2;
    }

    .vertical-text {
        @include bp(mobile) {
            display: none;
        }
    }
}

.device-container.iphone {
    width: vw(360);
    video {
        width: 88%;
        height: 88%;
        position: absolute;
        z-index: -1;
    }
    @include bp(tablet) {
        height: 80vh;
        margin: auto;
        width: 100%;
        object-fit: contain;
    }
    @include bp(mobile) {
        width: 75%;
        height: auto;
        align-self: center;
    }
}

.device-container.android {
    width: vw(360);
    video {
        width: 86%;
        height: 86%;
        position: absolute;
        z-index: -1;
    }
    @include bp(tablet) {
        height: 80%;
        width: auto;
        align-self: center;
    }
    @include bp(mobile) {
        width: 75%;
    }
}

.device-container.ipad {
    margin: 0 vw(var(--gutter));
    z-index: 1;
    @include bp(mobile) {
        margin: 0;
    }
    video {
        width: 86%;
        height: 82%;
        position: absolute;
        z-index: -1;
    }
}

// ------------------------------------

aside .device-container.ipad {
    margin-left: vw(calc(var(--gutter) / 2));
    margin-right: vw(calc(var(--gutter) / 2));
}

// Icon Play
// ------------------------------------

.device-container .icon-play {
    position: absolute;
    width: vw(64);
    min-width: 48px;
    height: auto;
    z-index: 1;
    cursor: pointer;
    fill: white;
    opacity: 0;
    transition: opacity 0.15s ease-in;
    path {
        pointer-events: none;
    }
    &:hover {
        opacity: 1;
        transition: opacity 0.15s ease-out;
    }
}

.device-container:hover .icon-play {
    opacity: 0.7;
} 

.is-playing.device-container:hover .icon-play {
    opacity: 0.025;
    &:hover {
        opacity: 1;
    }
}

</style>

